import axios, { AxiosInstance } from 'axios';

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `${token}`;
  }
  return config;
});

api.interceptors.response.use(async (response) => {
  return response;
}, async (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('token');
    window.location.pathname = '/login';
  }
})

export const register = (email: string, password: string) => api.post('/api/register', { email, password });
export const login = (email: string, password: string) => api.post('/api/login', { email, password });
export const updateStatus = (status: string) => api.post('/api/status', { status });
export const getLatestStatus = () => api.get('/api/status/latest');
export const getStatusHistory = () => api.get('/api/status/history');
export const getLastDayStatuses = () => api.get('/api/status/lastday');

export default api;