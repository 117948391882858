import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import StatusPage from './components/StatusPage';

const App: React.FC = () => {
  return (
    <Router>
      <div className="page-container">
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/status"
            element={
              localStorage.getItem('token') ? (
                <StatusPage />
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route path="/" element={
              localStorage.getItem('token') ? (
                <Navigate to="/status" replace />
              ) : (
                <Navigate to="/login" replace />
              )
            } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;