import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { updateStatus, getLatestStatus, getStatusHistory, getLastDayStatuses } from '../services/api';

dayjs.extend(relativeTime);

interface Status {
  id: number;
  user_id: number;
  status: string;
  category: string;
  created_at: string;
}


interface TimeElapsedProps {
  timestamp: string;
}

const TimeElapsed: React.FC<TimeElapsedProps> = ({ timestamp }) => {
  const [elapsedTime, setElapsedTime] = useState(dayjs().to(dayjs(timestamp)));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setElapsedTime(dayjs().to(dayjs(timestamp)));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timestamp]);

  return <span className="time-elapsed">{elapsedTime}</span>;
};

interface StatusWithDuration {
  id: number;
  user_id: number;
  status: string;
  category: string;
  created_at: string;
  duration: number;
}

const StatusBarGraph: React.FC = () => {
  const [statuses, setStatuses] = useState<StatusWithDuration[]>([]);

  useEffect(() => {
    fetchLast24HoursStatuses();
  }, []);

  const [hoverStatus, setHoverStatus] = useState<string | null>(null);

  const fetchLast24HoursStatuses = async () => {
    try {
      const response = await getLastDayStatuses();
      setStatuses(response.data.statuses);
    } catch (error) {
      console.error('Error fetching last 24 hours statuses:', error);
    }
  };

  const totalSeconds = 24 * 60 * 60; // 24 hours in seconds

  const getColorForStatus = (status: string): string => {
    // DJB2 hash function
    function djb2(str: string): number {
        let hash = 5381;
        for (let i = 0; i < str.length; i++) {
            hash = (hash * 33) ^ str.charCodeAt(i);
        }
        return hash >>> 0; // Ensure positive integer
    }
    
    // Helper function to get a value within a range based on the hash
    function getHashValue(hash: number, min: number, max: number, step: number) {
        const range = Math.floor((max - min) / step) + 1;
        return min + (hash % range) * step;
    }

    const hash = djb2(status);
    const hue = getHashValue(hash, 0, 360, 12);
    const saturation = getHashValue(hash, 60, 100, 5);
    const lightness = getHashValue(hash, 65, 85, 5);

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  function humanizeDuration(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    
    const hoursStr = hours > 0 ? `${hours}h ` : '';
    const minutesStr = minutes > 0 ? `${minutes}m ` : '';
    const secondsStr = `${secs}s`;
    
    return `${hoursStr}${minutesStr}${secondsStr}`.trim();
  }

  function groupAndSummarize(data: StatusWithDuration[]): [string, number][] {
    const groupedData: { [key: string]: number } = {};

    data.forEach(item => {
        if (!groupedData[item.status]) {
            groupedData[item.status] = 0;
        }
        groupedData[item.status] += item.duration;
    });

    const summarizedData: [string, number][] = Object.entries(groupedData).sort((a, b) => b[1] - a[1]);
    return summarizedData;
  }

  const summarisedStatuses = groupAndSummarize(statuses);

  return (
    <div className="status-bar-graph">
      <h4>Last 24 hours</h4>
      <div className="graph-container">
        {statuses.map((status) => (
          <div
            key={status.id}
            className={`status-bar ${hoverStatus !== null && hoverStatus !== status.status ? 'not-hover' : ''}`}
            style={{
              width: `${(status.duration / totalSeconds) * 100}%`,
              backgroundColor: getColorForStatus(status.status),
            }}
            title={`${status.status} - ${new Date(status.created_at).toLocaleString()}`}
          >
          </div>
        ))}
      </div>
      <div className="timeline">
        <span>24 hours ago</span>
        <span>Now</span>
      </div>
      <div className="legend">
        {summarisedStatuses.map((statusAndDuration) => (
          <div key={statusAndDuration[0]} className="legend-item" onMouseEnter={() => { setHoverStatus(statusAndDuration[0]) }} onMouseLeave={() => { setHoverStatus(null) }}>
            <div 
              className="legend-color" 
              style={{ backgroundColor: getColorForStatus(statusAndDuration[0]) }}
            ></div>
            <span>{statusAndDuration[0]}</span>
            <small>{humanizeDuration(statusAndDuration[1])}</small>
          </div>
        ))}
      </div>
    </div>
  );
};

const StatusPage: React.FC = () => {
  const [currentStatus, setCurrentStatus] = useState('');
  const [currentStatusTimestamp, setCurrentStatusTimestamp] = useState<string | null>(null);
  const [newStatus, setNewStatus] = useState('');
  const [recentStatuses, setRecentStatuses] = useState<string[]>([]);
  const [mostUsedStatuses, setMostUsedStatuses] = useState<string[]>([]);

  useEffect(() => {
    fetchLatestStatus();
    fetchStatusHistory();
  }, []);

  const fetchLatestStatus = async () => {
    try {
      const response = await getLatestStatus();
      setCurrentStatus(response.data.status);
      setCurrentStatusTimestamp(response.data.created_at);
    } catch (error) {
      console.error('Error fetching latest status:', error);
    }
  };

  const fetchStatusHistory = async () => {
    try {
      const response = await getStatusHistory();
      const statuses: Status[] = response.data.statuses;
      
      // Get 5 most recent unique statuses
      const recentUnique = Array.from(new Set(statuses.map(s => s.status))).slice(0, 5);
      setRecentStatuses(recentUnique);

      // Get 5 most used statuses
      const statusCounts = statuses.reduce((acc: {[key: string]: number}, s) => {
        acc[s.status] = (acc[s.status] || 0) + 1;
        return acc;
      }, {});
      const sortedStatuses = Object.entries(statusCounts)
        .sort((a, b) => b[1] - a[1])
        .map(([status]) => status)
        .slice(0, 5);
      setMostUsedStatuses(sortedStatuses);
    } catch (error) {
      console.error('Error fetching status history:', error);
    }
  };

  const handleUpdateStatus = async () => {
    try {
      await updateStatus(newStatus);
      setNewStatus('');
      fetchLatestStatus();
      fetchStatusHistory();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleStatusClick = async (status: string) => {
    try {
      await updateStatus(status);
      fetchLatestStatus();
      fetchStatusHistory();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="status-page">
      <div className="status-display">
        {currentStatus !== '--stop--' && <span className="dot" style={{ color: 'green' }}>●</span>}
        {currentStatus === '--stop--' && <span className="dot" style={{ color: 'red' }}>●</span>}
        <div>
          {currentStatus !== '--stop--' && currentStatus}
          {currentStatus === '--stop--' && 'Tracking stopped'}
        </div>
        {currentStatusTimestamp && <TimeElapsed timestamp={currentStatusTimestamp} />}
      </div>
      <div className="vspace"></div>
      <div className="vspace"></div>
      <div className="vspace"></div>
      <div className="status-form">
        <input
          type="text"
          value={newStatus}
          onChange={(e) => setNewStatus(e.target.value)}
          placeholder="Enter new status"
        />
        <button onClick={handleUpdateStatus}>Update Status</button>
      </div>
      <div className="vspace"></div>
      <div className="status-recent">
        <div>
          <h3>Recent</h3>
          <ul>
            {recentStatuses.map((status, index) => (
              <li key={index} onClick={() => handleStatusClick(status)}>
                {status}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3>Frequent</h3>
          <ul>
            {mostUsedStatuses.map((status, index) => (
              <li key={index} onClick={() => handleStatusClick(status)}>
                {status}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="vspace"></div>
      <div className="vspace"></div>
      <div className="vspace"></div>
      <StatusBarGraph />
    </div>
  );
};

export default StatusPage;